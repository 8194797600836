<template>
  <!-- 主线路管理 -->
  <div ref="intercityMainLine" class="intercityMainLine">
    <!-- 操作栏 -->
    <div class="actionBar">
      <el-button
        v-if="listFind('添加')"
        type="primary"
        size="small"
        @click="
          () => {
            this.dialogVisible = true;
            this.title = '新增';
          }
        "
        >添加</el-button
      >
    </div>
    <!-- 表格 -->
    <Table
      class="mainLineTable"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :table-height="tableHeight"
      operation-width="120"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('查看')"
              type="text"
              size="small"
              sort="primary"
              @click="seeFun(scope.scopeRow)"
              >查看</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="danger"
              @click="updateFun(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper,sizes"
        :total="total"
        :page-size="pageSize"
        :page-sizes="[10, 30, 50, 100]"
        :current-page="currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>
    <!-- 新增|编辑 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="dialogVisible"
      width="490px"
      @close="onDialogClose"
    >
      <div>
        <div class="body">
          <el-form
            ref="addForm"
            :model="addForm"
            :rules="rules"
            label-width="110px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="线路代码：" prop="lineCode">
              <el-input
                v-model.trim="addForm.lineCode"
                :disabled="disabled || title == '编辑' ? true : false"
                placeholder="请输入线路代码"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="线路名称：" prop="lineName">
              <el-input
                v-model.trim="addForm.lineName"
                :disabled="disabled"
                placeholder="请输入线路名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="起点检索名称：" prop="startStation">
              <el-input
                v-model.trim="addForm.startStation"
                :disabled="disabled"
                placeholder="请输入起点检索名称"
                onkeyup="this.value = this.value.replace(/-/g,'')"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="终点检索名称：" prop="endStation">
              <el-input
                v-model.trim="addForm.endStation"
                :disabled="disabled"
                onkeyup="this.value = this.value.replace(/-/g,'')"
                placeholder="请输入终点检索名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="起点行政区划：" prop="startCityCodes">
              <el-cascader
                ref="cascader"
                v-model="addForm.startCityCodes"
                :disabled="disabled"
                :options="cityList"
                :props="props"
                @change="handleCascaderCity(1)"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="终点行政区划：" prop="endCityCodes">
              <el-cascader
                ref="cascader2"
                v-model="addForm.endCityCodes"
                :disabled="disabled"
                :options="cityList"
                :props="props"
                @change="handleCascaderCity(2)"
              ></el-cascader>
            </el-form-item>
            <!-- v-show="listFind('可售渠道')" -->
            <el-form-item label="可售渠道：" prop="vendibilityChannelNo">
              <el-select
                v-model="addForm.vendibilityChannelNo"
                :disabled="disabled"
                multiple
                :collapse-tags="true"
                placeholder="请选择可售渠道"
              >
                <el-option
                  v-for="role in vendibilityList"
                  :key="role.channelNo"
                  :label="role.channelName"
                  :value="role.channelNo"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否启用：">
              <el-select
                v-model="status"
                :disabled="disabled"
                clearable
                placeholder="请选择线路启用"
              >
                <el-option
                  v-for="role in statusList"
                  :key="role.value"
                  :label="role.label"
                  :value="role.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="
            () => {
              dialogVisible = false;
              seeShow = true;
            }
          "
          >取消</el-button
        >
        <el-button
          v-show="seeShow"
          :loading="editLoading"
          type="primary"
          size="small"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCityCodeAPI,
  saveIntercityMainLineAPI,
  queryIntercityMainLinePageAPI,
  updateIntercityMainLineAPI,
  queryChannelListAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      status: 0,
      pageSize: 10,
      currentPage: 1,
      total: 0,
      disabled: false,
      dialogVisible: false,
      title: "",
      titleName: [
        {
          title: "线路代码",
          props: "lineCode",
        },
        {
          title: "线路名称",
          props: "lineName",
        },
        {
          title: "起点检索名称",
          props: "startStation",
        },
        {
          title: "终点检索名称",
          props: "endStation",
        },
        {
          title: "起点行政区划",
          props: "startCityName",
        },
        {
          title: "终点行政区划",
          props: "endCityName",
        },
        {
          title: "可售渠道",
          props: "vendibilityChannelName",
          SpecialJudgment: (res) => {
            return res ? res.join("，") : "";
          },
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  if (val) {
                    this.status = 0;
                  } else {
                    this.status = 1;
                  }
                  this.title = "编辑";
                  let obj = this.deepClone(params);
                  obj.status = this.status;
                  updateIntercityMainLineAPI(obj).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.renderData();
                    }
                  });
                  // this.$set(this.statusArray, index, val);
                  // lineDisableAPI({
                  //   id: params.id,
                  //   status: !params.status
                  // }).then(res => {
                  //   if (res.code == "SUCCESS") {
                  //     this.renderData();
                  //   }
                  // });
                },
              },
            });
          },
        },
      ],
      tableData: [],
      rules: {
        lineCode: [
          { required: true, message: "请输入线路代码", trigger: "blur" },
        ],
        lineName: [
          { required: true, message: "请输入线路名称", trigger: "blur" },
        ],
        startStation: [
          { required: true, message: "请输入起点检索名称", trigger: "blur" },
        ],
        endStation: [
          { required: true, message: "请输入检索名称", trigger: "blur" },
        ],
        startCityCodes: [
          { required: true, message: "请选择必填项", trigger: "change" },
        ],
        endCityCodes: [
          { required: true, message: "请选择必填项", trigger: "change" },
        ],
        vendibilityChannelNo: [
          { required: true, message: "请选择可售渠道", trigger: "change" },
        ],
      },
      statusList: [
        { value: 0, label: "正常" },
        { value: 1, label: "禁用" },
      ],
      // 表格查询参数
      parameter: {},
      // 新增编辑参数
      addForm: {
        lineCode: "", // 线路代码
        lineName: "", // 线路名称
        startStation: "", // 起点检索名称
        endStation: "", // 终点检索名称
        startCityCode: "", // 起点行政区划城市代码
        startCityCodes: "",
        startCityName: "", // 起点行政区划城市名称
        endCityCode: "", // 终点行政区划城市代码
        endCityCodes: "",
        endCityName: "", // 终点行政区划城市名称
        status: 0,
        vendibilityChannelNo: [], //可售渠道集合
      },
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      cityList: [],
      seeShow: true,
      editLoading: false,
      channelList: [], // 所属渠道下拉框数据
      vendibilityList: [], // 可售渠道下拉框数据
      tableHeight: 0,
    };
  },
  created() {
    this.getCityCode();
    this.renderData();
    // 获取所属渠道下拉框数据
    queryChannelListAPI({
      status: 0,
    }).then((res) => {
      if (res.code == "SUCCESS") {
        this.channelList = res.data;
        this.vendibilityList = res.data;
      }
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.intercityMainLine?.clientHeight;
      this.tableHeight = wholeHeight - 154;
    },
    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.renderData();
      this.computeHeight();
    },
    // 点击查看事件
    seeFun(val) {
      this.title = "查看";
      this.addForm = this.deepClone(val);
      this.dialogVisible = true;
      this.disabled = true;
      this.seeShow = false;
    },
    // 点击编辑事件
    updateFun(val) {
      this.addForm = this.deepClone(val);
      this.status = val.status;
      this.title = "编辑";
      this.dialogVisible = true;
    },
    //获取城市代码
    getCityCode() {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = this.getTreeData(res.data[0].districts);
        }
      });
    },
    getTreeData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].districts.length < 1) {
          data[index].districts = undefined;
        } else {
          this.getTreeData(data[index].districts);
        }
      }
      return data;
    },
    // 关闭弹窗时的回调
    onDialogClose() {
      console.log("关闭");
      this.addForm = {
        lineCode: "", // 线路代码
        lineName: "", // 线路名称
        startStation: "", // 起点检索名称
        endStation: "", // 终点检索名称
        startCityCode: "", // 起点行政区划城市代码
        startCityCodes: "",
        startCityName: "", // 起点行政区划城市名称
        endCityCode: "", // 终点行政区划城市代码
        endCityCodes: "",
        endCityName: "", // 终点行政区划城市名称
        status: 0,
        vendibilityChannelNo: [], //可售渠道集合
      };
      this.status = 0;
      this.seeShow = true;
      this.editLoading = false;
      this.disabled = false;
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    //选择城市
    handleCascaderCity(val) {
      if (val === 1) {
        const node = this.$refs.cascader.getCheckedNodes();
        this.addForm.startCityName = node[0].pathLabels.join(",");
      } else if (val === 2) {
        const node2 = this.$refs.cascader2.getCheckedNodes();
        this.addForm.endCityName = node2[0].pathLabels.join(",");
      }
    },
    // 获取表格数据
    renderData() {
      queryIntercityMainLinePageAPI({
        pageSize: this.pageSize,
        currentPage: this.currentPage,
      }).then((res) => {        
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 点击保存按钮的时候
    onSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          let obj = this.deepClone(this.addForm);
          obj.startCityCode = [
            obj.startCityCodes[obj.startCityCodes.length - 1],
          ];
          obj.endCityCode = [obj.endCityCodes[obj.endCityCodes.length - 1]];
          obj.status = this.status;
          if (this.title == "新增") {
            saveIntercityMainLineAPI(obj)
              .then((res) => {
                if (res.code == "SUCCESS") {
                  this.currentPage = 1;
                  this.renderData();
                  this.editLoading = false;
                  this.dialogVisible = false;
                }
              })
              .catch((res) => {
                this.editLoading = false;
              });
          } else if (this.title == "编辑") {
            updateIntercityMainLineAPI(obj)
              .then((res) => {
                if (res.code == "SUCCESS") {
                  this.renderData();
                  this.editLoading = false;
                  this.dialogVisible = false;
                }
              })
              .catch((res) => {
                this.editLoading = false;
              });
          }
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.currentPage = page;
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.intercityMainLine {
  height: 100%;
}
.actionBar {
  margin-bottom: 20px;
}
</style>
